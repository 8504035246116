import React, { useState } from "react";
import Layout from "../components/reusable/Layout";
import { useTranslation } from "gatsby-plugin-react-i18next";
import { landingPalette } from "../styles/styles";
import { graphql } from "gatsby";
import axios from "axios";
import CircularProgress from "@mui/material/CircularProgress";
import { countries, countryToFlag } from "../utils/utils";
import "react-notifications/lib/notifications.css";
import { NotificationManager } from "react-notifications";
import { Backdrop } from "@mui/material";
import Seo from "../components/reusable/Seo";
import {
  Instagram,
  Tiktok,
  Facebook,
  Twitter,
  Youtube,
} from "styled-icons/fa-brands";
import { ContactSection } from "../styles/pages/contact";

const Contact = (props) => {
  const { t } = useTranslation("contact");

  const [open, setOpen] = useState(false);
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    companyName: "",
    phone: "",
    phoneCode: 52,
    helpOption: "ats",
    body: "",
    userType: "chip-candidate",
  });

  const options = [
    { value: "ats", label: t("helpOption1") },
    { value: "inst", label: t("helpOption2") },
    { value: "hh", label: t("helpOption3") },
    { value: "other", label: t("helpOption5") },
  ];

  const onChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevState) => ({ ...prevState, [name]: value }));
  };

  const onSubmit = (e) => {
    e.preventDefault();
    toggleBackdrop();

    let email;
    switch (formData.helpOption) {
      case "ats":
        email = [
          "alexa.villegas@lapieza.io ",
          "elena@lapieza.io",
          "marijose.m@lapieza.io",
        ];
        break;
      case "inst":
        email = [
          "alexa.villegas@lapieza.io ",
          "elena@lapieza.io",
          "marijose.m@lapieza.io",
        ];
        break;
      case "hh":
        email = [
          "alexa.villegas@lapieza.io ",
          "elena@lapieza.io",
          "marijose.m@lapieza.io",
        ];
        break;
      default:
        email = [
          "alexa.villegas@lapieza.io ",
          "elena@lapieza.io",
          "marijose.m@lapieza.io",
        ];
    }

    const payload = {
      email,
      data: {
        ...formData,
        helpOption: options.find((op) => op.value === formData.helpOption)
          .label,
      },
      templateId: "fd7f8ad5239b4e7097bcad8591999b65",
    };

    sendEmail(payload)
      .then((message) => {
        toggleBackdrop();
        setFormData({
          name: "",
          email: "",
          companyName: "",
          phone: "",
          phoneCode: 52,
          helpOption: "",
          body: "",
        });
        NotificationManager.success(t("messageSent"), null, 3000);
      })
      .catch(() => {
        toggleBackdrop();
        NotificationManager.error(t("messageFailed"), null, 3000);
      });
  };

  const sendEmail = (data = {}) => {
    return axios.request({
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Content-Type": "application/json",
      },
      url: "https://us-central1-pieza-development.cloudfunctions.net/sendMailCandidateInvitation",
      method: "POST",
      data,
      crossDomain: true,
    });
  };

  const toggleBackdrop = () => {
    setOpen((prevState) => !prevState);
  };

  const onSelectedChip = (userType) => {
    setFormData((prevValues) => ({ ...prevValues, userType }));
  };

  return (
    <>
      <Seo
        canonical="https://empresas.lapieza.io/contact"
        title="Contacto - Resolvemos tus dudas"
        image="https://firebasestorage.googleapis.com/v0/b/lapieza-production.appspot.com/o/EmpresasATS%2Fpreviews%2FLANDING%20EMPRESAS.png?alt=media&token=3dd56d07-4544-4eef-ab5c-5b53a475137e"
      />
      <Layout>
        <ContactSection>
          <div className="main-landing-container-row">
            <article>
              <h1>{t("contactTitle")}</h1>
              <h2>{t("contactSubTitle")}</h2>
              <p>{t("contactDesc")}</p>
              <div className="contact-network">
                <h5>{t("contacSocialLabel")}</h5>
                <div>
                  <a
                    href="https://www.instagram.com/lapieza.io/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <Instagram size={35} color={landingPalette.blue} />
                  </a>
                  <a
                    href="https://www.tiktok.com/@lapieza.io"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <Tiktok size={35} color={landingPalette.blue} />
                  </a>
                  <a
                    href="https://www.facebook.com/lapieza.io/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <Facebook size={35} color={landingPalette.blue} />
                  </a>
                  <a
                    href="https://twitter.com/lapiezaio?s=21"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <Twitter size={35} color={landingPalette.blue} />
                  </a>
                  <a
                    href="https://www.youtube.com/channel/UC2In7Cukf5UJHU8-SmDRWPg"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <Youtube size={35} color={landingPalette.blue} />
                  </a>
                </div>
              </div>
            </article>
            <form onSubmit={onSubmit}>
              <input
                type="text"
                placeholder={t("name")}
                name="name"
                value={formData.name}
                onChange={onChange}
                required
              />
              <input
                type="email"
                placeholder={t("email")}
                name="email"
                value={formData.email}
                onChange={onChange}
                required
              />
              <div className="select-input">
                <select
                  name="phoneCode"
                  value={formData.phoneCode}
                  onChange={onChange}
                  required
                >
                  {countries.map((item) => (
                    <option key={item.name} value={item.phoneCode}>
                      {countryToFlag(item?.iso2 || "")}{" "}
                      {`  +${item.phoneCode || ""}`}
                    </option>
                  ))}
                </select>
                <input
                  type="number"
                  placeholder={t("phone")}
                  name="phone"
                  value={formData.phone}
                  onChange={onChange}
                  required
                />
              </div>
              <div className="form-input">
                <label>{t("typeUser")}</label>
                <div className="type-user-list">
                  <span
                    className={
                      formData.userType === "chip-candidate"
                        ? "chip-selected"
                        : ""
                    }
                    onClick={() => onSelectedChip("chip-candidate")}
                  >
                    {t("candidateOption")}
                  </span>
                  <span
                    className={
                      formData.userType === "chip-company"
                        ? "chip-selected"
                        : ""
                    }
                    onClick={() => onSelectedChip("chip-company")}
                  >
                    {t("companyOption")}
                  </span>
                </div>
              </div>
              <div className="form-input">
                <label htmlFor="reason">{t("contactReason")}</label>
                <select
                  name="helpOption"
                  value={formData.helpOption}
                  onChange={onChange}
                  required
                >
                  {options.map((o) => (
                    <option key={o.value} value={o.value}>
                      {o.label}
                    </option>
                  ))}
                </select>
              </div>
              <textarea
                name="body"
                id="body"
                cols="30"
                rows="10"
                value={formData.body}
                placeholder={t("messageText")}
                onChange={onChange}
              ></textarea>
              <button type="submit">{t("nextButton")}</button>
            </form>
          </div>
        </ContactSection>
        <Backdrop
          sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={open}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
      </Layout>
    </>
  );
};

export default Contact;

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
